.card {
  position: relative;
}

.CardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.CarBody {
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .card:hover .CarBody {
    right: 0;
  } */
.CardBtn {
  background: #8fabba;
  color: black;
}
