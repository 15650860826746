.mainImage {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 1196px) {
  .container {
    margin-top: 100px !important;
  }
}
