/* src/healthacademy.module.css */

.container {
  margin-top: 150px;
  padding: 60px !important;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* .heading {
  color: #2c3e50;
} */

.subheading {
  margin-top: 15px;
  margin-bottom: 10px;
}

.card {
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  margin-bottom: 5px;
  background-color: #ecf0f1;
  padding: 10px;
  border-radius: 4px;
}
.img {
  width: "30% !important";
  height: "30% !important";
}

@media (max-width: 768px) {
  .container {
    margin-top: 110px;
    padding: 30px !important;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 1000px) {
  .container {
    margin-top: 110px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
