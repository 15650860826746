.HomeContainer {
  width: 90% !important;
}

.HomeSection {
  /* background-color: #d5e9f9; */
  background-color: #e6f7fd;

  margin-top: 130px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
}

.HomeImageFirstSession {
  display: flex;
  justify-content: center;
  align-content: center;
}

.HomeImage {
  width: 100%;
  height: auto;
}

.HeadingTypography {
  color: var(--dark, #211e1e);
  font-family: Inter;
  font-size: 3.75rem;
  font-weight: 700 !important;
  line-height: 122.523%;
  letter-spacing: 1.3px;
}

.HeadingTypography {
  color: var(--dark, #211e1e);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0.44px;
}

.DotctorsTalk {
  color: var(--dark, #211e1e);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-decoration-line: underline;
  text-transform: none;
  padding-left: 12px;
}

.BookAppoinmentButton {
  width: 238px;
  height: 59px;

  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
  padding: 100px;
}

.bookAppoinmentSection {
  margin-top: -159px !important;
}

.bookAppoinmentcard {
  width: 70% !important;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.03);
  background: #fff;
  display: flex;

  padding: 20px;
}

.BookAppoinmentCardButton {
  width: 100%;
  height: 58px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
  padding: 100px;
}

.DepartmentTypography {
  color: var(--dark, #211e1e);
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.523%;
  letter-spacing: 0.7px;
  text-transform: none;
}

.WhyGgHospital {
  background-color: #eaf2ff;
  /* height: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  /* margin-top: 150px; */
}

.Greentick {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}

.WhyGgSecondPart {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 200px;
}

.WhyGgBackgroundImage {
  width: 100%;
  height: 100%;
}

.MeetOurSpecialist {
  margin-top: 200px;
}

.MeetOurSpecialistfistsection {
  display: flex;
  justify-content: center;
  align-content: center;
}

.SpecialistImage {
  width: 90%;
  /* height: 336px; */
  height: 90%;
  flex-shrink: 0;
  object-fit: scale-down;
  border-radius: 15px !important;
}

.ViewAllButtonGrid {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 168px;
}

.ViewAllButton {
  width: 179px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
}

.FacilitiesAndActivities {
  margin-top: 150px;
}

.FacilitiesAndActivitiesfistsection {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
}

.HomeSecondSection {
  background-color: #eaf2ff;
  margin-top: 350px;
  height: 958px;
}

.YouMayInterested {
  margin-top: -233px;
}

.Subscribesection {
  background-color: white;
  width: 1656px;
  height: 323px;
  background-image: url("../../assets/images/subscribebackground.png");
  background-repeat: no-repeat;
}

.Subscribeleftsection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.Subscriberightsection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 1% !important;
}

.mainImage {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.embededSection {
  margin-top: 50px !important;
  margin-top: 50px !important;

  align-items: center !important;
  justify-content: center !important;
}

@media (max-width: 1571px) {
  .WhyGgHospital {
    background-color: #eaf2ff;

    height: 100% !important;
    display: flex;
    flex-direction: column;

    margin-top: 200px;
  }

  .WhyGgFirstPart {
    height: auto;
  }

  .WhyGgSecondPart {
    display: flex;

    margin: 10% !important;
  }

  .WhyGgBackgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1221px) {
  .WhyGgBackgroundImage {
    width: 100%;
    height: 100%;
  }
  .WhyGgHospital {
    margin-top: 80px;
  }
  .MeetOurSpecialist {
    margin-top: 100px;
  }
  .FacilitiesAndActivities {
    margin-bottom: -10px;
  }
}

@media (max-width: 1196px) {
  .HomeImageFirstSession {
    margin: 90px !important;
  }

  .HomeSection {
    margin-top: 100px !important;
  }

  .HomeContainer {
    width: 100% !important;
  }

  .SpecialistSectionContainer {
    margin-top: 10% !important;
  }

  .bookAppoinmentSection {
    margin-top: -70px !important;
  }

  .bookAppoinmentcard {
    width: 90% !important;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.03);
    background: #fff;
    display: flex;
    padding: 20px;
  }
  .WhyGgHospital {
    margin-top: 80px;
  }
}

@media (min-width: 1200px) and (max-width: 1393px) {
  .bookAppoinmentSection {
    margin-top: -90px !important;
  }

  .bookAppoinmentcard {
    width: 90% !important;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.03);
    background: #fff;
    display: flex;
    padding: 20px;
  }
}

@media (min-width: 1196px) and (max-width: 1199px) {
  .bookAppoinmentSection {
    margin-top: 10px !important;
  }
}
