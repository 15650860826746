.MapImage {
  width: 100%;
  height: 397px;
}
.ViewAllButton {
  width: 211px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
}
