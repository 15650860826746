.whyMsigmaCarouselCard {
  width: 100%;
  min-height: 300px;
  /* background: #ffffff;
    padding: 2em;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 5em;
  justify-content: space-between;
}
.SwiperImage {
  width: 100%;
  height: 327px;
}
.DotctorsTalk {
  color: #17c1a3;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 186%;
  letter-spacing: 0.36px;
  text-decoration-line: underline;
  text-transform: none;
}
