.slide {
  width: 26em !important;
}
.carouselWrapper {
  /* max-width: 1200px; */
  /* padding-inline: 5em !important;
  margin-bottom: 1em; */
}
.whyMsigmaCarouselCard {
  width: 100%;
  min-height: 300px;
  /* background: #ffffff;
  padding: 2em;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px; */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5em; */
  justify-content: space-between;
}

.testimonialText {
  text-align: justify;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.testimonialUser {
  padding-top: 2em;
  display: flex;
  align-items: center;
}
.testimonialUserDetails {
  margin-left: 1em;
}
.testimonialUserName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1b75bb;
}
.testimonialUserProfession {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #555555;
}

.SwiperImage {
  width: 529px;
  height: 327px;
}
.SwiperHead {
  /* color: var(--dark, #211e1e);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.523%; */
}
.DotctorsTalk {
  color: #17c1a3;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 186%;
  letter-spacing: 0.36px;
  text-decoration-line: underline;
  text-transform: none;
  cursor: pointer;
}
@media (max-width: 900px) {
  .SwiperImage {
    width: 100%;
    height: 100%;
  }
  .slide {
    width: 100% !important;
  }
}
