.DoctorSelectInputsection {
  margin-top: 8px;
}
.container {
  margin-top: 100px;
}

@media (max-width: 1196px) {
  .container {
    width: 90%;
    margin-top: 50px;
  }
}
