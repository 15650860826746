.gglogo {
  width: 13%;
}
.toolpbar {
  margin-left: 64px;
  margin-right: 64px;
}
@media (max-width: 1196px) {
  .gglogo {
    width: auto;
  }
  .toolpbar {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
