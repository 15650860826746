.aboutBannerForms {
  display: flex;
}
.aboutBanner {
  font-family: "Inter", sans-serif;
  background-image: url(../../assets/images/accreditationBG.png);
  background-color: #eaf2ff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  height: 850px;
  display: flex;
  align-content: center;
  margin-bottom: 100px;
  margin-left: 0px !important;
  width: 90%;
  padding: 30px;
}
.ViewAllButton {
  width: 211px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
}

@media (max-width: 900px) {
  .aboutBanner {
    background-image: none;
    background-color: #eaf2ff;
  }
}
