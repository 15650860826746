.CareerCard {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.03);
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.ViewAllButton {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
  padding: 100px;
}
.CareerContainer {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
