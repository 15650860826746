.container {
  background-color: #f5f5f5;
  height: 45px;
  z-index: 1;
}

.firstsection {
  background-color: aqua;
}
.secondsection {
  background-color: blue;
}
