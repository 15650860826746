/* .wrapper {
  background: url(../../assets//images//familyclinic-banner.png);
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
} */
.banner {
  background: url(../../assets//images//familyclinic-banner.png);
  background-size: cover; /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  background-position: center;
  min-height: 110vh;
}
.bannerCard {
  background: url(../../assets//images/fc-bannercard-background.png);
  background-size: cover; /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  /* height: 100px; */
  min-width: 70%;
  max-width: 80%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 4px;
}
.department {
  background-color: #f0faff;
  /* min-height: 90vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .banner {
    background: url(../../assets//images//familyclinic-banner.png);
    background-size: cover; /* or 'contain' based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    height: 110vh;
  }
  .bannerCard {
    background: url(../../assets//images/fc-bannercard-background.png);
    background-size: cover; /* or 'contain' based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    /* height: 100px; */
    min-width: 70%;
    max-width: 80%;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 4px;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
