.DoctorSelectInputsection {
  margin-top: 37px;
}
.DepartmentCard {
  margin-bottom: 519px;
  /* align-items: center !important; */
}
.container {
  width: 90% !important;
  margin-top: 100px !important;
}
@media (max-width: 1196px) {
  .DepartmentContainer {
    width: 100% !important;
  }
}
