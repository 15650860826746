.BookAppoinmentButton {
  width: 238px;
  height: 59px;

  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--green, #17c1a3) !important;
  text-transform: none;
  padding: 100px;
}

.Doctor_doctorImage__YSKuW {
  width: 60%;
  height: 456px;
  border-radius: 5px;
  object-fit: contain;
}

@media (max-width: 1196px) {
  .doctorImage {
    width: 90%;
    height: 336px;
  }

  .secondSection {
    align-items: center !important;
    justify-content: center !important;
  }

  .qualification {
    text-align: center !important;
  }
  .Doctor_doctorImage__YSKuW {
    width: 80%;
    height: auto;
  }
  .opTimings {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    align-content: center;
    margin-left: 0px !important;
  }
}
