@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container {
  width: 90% !important;
  margin-top: 200px !important;
}

.welcomeSect {
  display: flex;
  justify-content: space-between;
}
.welcomeSectLeft {
  font-family: "Inter", sans-serif !important;
}
.welcomeSectRight {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 195%; /* 35.1px */
  letter-spacing: 0.36px;
}

.aboutBanner {
  font-family: "Inter", sans-serif;
  background-image: url(../../assets/images/aboutBanner.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 564px;
  display: flex;
  align-content: center;
  margin-bottom: 100px;
}
.aboutBannerLogo {
  background-image: url(../../assets/images/ggshortlogo.png);
  height: 220px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.aboutSect {
  display: flex;
  justify-content: space-around;
}
.aboutSectLeft {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 600;
}
.aboutsectLeftDesc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.accordionTitle {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 180%; /* 43.2px */
}
.accordionDesc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
.adminCard {
  border: none;
  box-shadow: none;
  background-color: black;
  position: relative;
}
.adminCard :hover {
  opacity: 0.5;
}
.hoverButton {
  background-color: #17c1a3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hoverButton:hover {
  background-color: #009c7f;
  opacity: 1;
}
.accSect {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.accCardImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 324px;
  border-radius: 4px;
  border: 1.206px solid rgba(10, 10, 10, 0.2);
  box-shadow: none;
}

.googleMap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 60px;
}
@media (max-width: 1196px) {
  .container {
    width: 90%;
    margin-top: 50px;
  }
}

/* Image for tablets */
@media (max-width: 1000px) {
  .aboutBanner {
    background-image: url(../../assets/images/AboutBannerTab.png);
    margin-bottom: 100px;
  }
}

/* Image for mobile screens */
@media (max-width: 767px) {
  .aboutBanner {
    background-image: url(../../assets/images/AboutBannerMobile.png);
    margin-bottom: 30px;
  }
}
