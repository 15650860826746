.card {
  position: relative;
}

.CardImg {
  width: 100%;
  height: 100%;
  /* object-fit: scale-down; */
  border-radius: 10px;
}
.CarBody {
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  position: absolute;
  background: rgba(11, 62, 52, 0.648);

  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .card:hover .CarBody {
  right: 0;
} */
.CardBtn {
  background: #8fabba;
  color: black;
}
