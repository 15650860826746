.gglogo {
	width: 20%;
}
.toolpbar {
	/* margin-left: 64px;
  margin-right: 64px; */
}

/* .appBar {
  color: "black";
  background: url(../../assets//images/fc-nav-background.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
} */
@media (max-width: 1196px) {
	.gglogo {
		width: 30%;
	}
	.toolpbar {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}
@media (max-width: 760px) {
	.gglogo {
		width: 75%;
	}
	.toolpbar {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}
